<template>
    <div class="action-photo">
        <div class="section-photos">
            <div class="timestamp" v-if="me">{{ message.created_at | formatDate('A h:mm') }}</div>
            <!--내 프로필 심사  -->
            <MyProfilePhtoLayout v-if="message.mtype === 'self-profile-photo'" :message="message" />
            <PhotoLayout
                v-else-if="message.mtype === 'photo' && !isBlindTester"
                :message="message"
                :user="content"
                :image="imageUrl"
                :isBlindTester="isBlindTester"
                @seeProfile="seeProfile"
            />
            <PhotoLayout
                v-else-if="message.mtype === 'photo' && isBlindTester"
                :message="message"
                :user="content"
                :image="require('@/assets/images/logo_symbol.png')"
                :isBlindTester="isBlindTester"
                @seeProfile="seeProfile"
            />
            <ProfilePhotoLayout
                v-else-if="message.mtype === 'profile-photo'"
                @click="onClickImage"
                :message="message"
                :profilePhotoMessage="profilePhotoMessage"
            />
            <MultiPhotoLayout
                v-else
                @click="onClickImage"
                @seeProfile="seeProfile"
                :message="message"
                :user="content"
            />
            <div
                class="timestamp"
                v-if="!me && message.mtype !== 'multi-photo' && message.mtype !== 'self-profile-photo'"
            >
                {{ message.created_at | formatDate('A h:mm') }}
            </div>
        </div>
    </div>
</template>

<script>
import MultiPhotoLayout from './MultiPhotoLayout'
import ProfilePhotoLayout from './ProfilePhotoLayout'
import MyProfilePhtoLayout from './MyProfilePhotoLayout'
import PhotoLayout from './PhotoLayout'
import userService from '@/services/user'

export default {
    name: 'ActionPhoto',
    props: ['message', 'profilePhotoMessage'],
    components: { MultiPhotoLayout, ProfilePhotoLayout, PhotoLayout, MyProfilePhtoLayout },
    watch: {
        content(newVal) {
            if (newVal) this.imageUrl = newVal.url
        },
    },
    computed: {
        me() {
            return this.message.user_id === this.$store.getters.me.id
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photos() {
            const messages = (this.chat || {}).$$messages
            let photos = []
            const photoMessages = (messages || []).filter(m => m.mtype.includes('photo')) || []
            photoMessages.forEach(m => {
                const c = this.$mustParse(m.content)
                if (c.url) photos.push(c.url)
                if (c.urls) photos = photos.concat(c.urls.reverse())
            })

            return photos.reverse()
        },
        chat() {
            return this.$store.getters.chat
        },
        isBlindTester() {
            return (
                (this.$store.getters.me.blind_test && this.$store.getters.me.gender === 1) ||
                (this.me.products || []).find(p => p.product_id === 60)
            )
        },
    },
    data: () => ({
        imageUrl: null,
    }),
    mounted() {
        this.imageUrl = this.content.url
    },
    methods: {
        onClickImage(pivotImage) {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }

            let selectedIndex
            this.photos.forEach((photo, idx) => {
                if (photo === pivotImage) selectedIndex = idx
            })

            this.$modal.images({
                images: this.photos,
                selectedIndex,
            })
        },
        getUserDetail() {
            return userService.detail(this.content.source_id)
        },
        async seeProfile() {
            if (this.content.show_photo === false) {
                this.$toast.error('탈퇴 혹은 휴면했거나 활동이 정지된 회원입니다.')
                return
            }
            const photos = () => {
                let photos = []
                if (this.content.url) {
                    photos.push(this.content.url)
                }

                if (this.content.urls) {
                    photos = photos.concat(this.content.urls)
                }

                return photos.map(p => ({ url: p }))
            }

            try {
                if (this.isBlindTester) {
                    this.$stackRouter.push({
                        name: 'UserBlindProfilePage',
                        props: {
                            userId: this.content.source_id,
                            photos: photos(),
                        },
                    })
                } else {
                    this.$stackRouter.push({
                        name: 'UserDetailPage',
                        props: {
                            userId: this.content.source_id,
                            photos: photos(),
                            message: this.message,
                        },
                    })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
