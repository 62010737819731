<template>
    <div class="my-profile-photo-layout-container">
        <div class="profile-photo-layout">
            <div class="img-wrapper flex-wrap" @click="openProfile">
                <img class="src-img" v-lazy="images[0]" />
            </div>
            <div class="flex profile-wrapper" @click="openProfile">
                <div class="name-age" v-html="jobAge" />
                <div class="region-job m-b-8" v-html="region" />
                <div class="btns">
                    <!-- 상태에 따라 버튼 분기처리 추가 필요  -->
                    <!-- <button @click.stop="openProfile" class="btn btn-check" v-html="$translate('CHECK_PROFILE')" /> -->
                    <button
                        @click.stop="openProfile"
                        class="btn"
                        :class="content.user_confirmed ? 'btn-final' : 'btn-check'"
                        v-html="$translate(btnLabel)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyProfilePhotoLayout',
    props: ['message'],
    data: () => ({
        timer: null,
    }),
    watch: {},
    created() {},
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        images() {
            return this.content.urls
        },
        jobAge() {
            const { birthday } = this.content
            // const { birthday } = this.content
            // return `${job || '-'}, ${this.$options.filters.asAge(birthday)}`
            return this.$options.filters.asAgeInActionMessage(birthday)
            // return `${job_name ? job_name + ', ' : ''} ${this.$options.filters.asAge(birthday)}세`
        },
        region() {
            const { region } = this.content
            return `${this.$options.filters.noDetail(region) || '-'}`
        },
        chat() {
            return this.$store.getters.chat
        },
        agentId() {
            return this.chat.agent_id
        },
        btnLabel() {
            return this.content.user_confirmed ? 'CHECK_PROFILE_FINAL' : 'CHECK_PROFILE'
        },
    },
    methods: {
        throttle(handler, delay) {
            if (!this.timer) {
                this.timer = setTimeout(() => {
                    this.timer = null
                }, delay)

                handler()
            }
        },
        async openProfile() {
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.me.id,
                    from: 'MyProfileConFirm',
                    message: this.message,
                    chat: this.chat,
                    header: {
                        icon: 'back',
                        label: '프로필 확인',
                        handler: () => this.$stackRouter.pop(),
                    },
                },
            })
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.my-profile-photo-layout-container {
    width: calc(100vw - 120px);
    max-width: 260px;
    padding: 6px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.06), 0 2px 6px 0 rgba(138, 116, 255, 0.05);
}

.profile-photo-layout {
    display: flex;
    align-items: center;

    .img-wrapper {
        position: relative;
        width: 80px;
        height: 80px;
        margin-right: 12px;

        &.blurred::before {
            position: absolute;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(3px);
            pointer-events: none;
            border-radius: 8px;
            content: '';
        }

        .overlay {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            &:not(.blurred-overlay) {
                background: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .src-img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px $grey-02;
    }

    .profile-wrapper {
        width: calc(100% - 92px);
    }

    .name-age {
        margin-bottom: 4px;
        color: black;
        font-size: 14px;
        @include f-medium;
    }

    .region-job {
        color: $grey-08;
        font-size: 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
            color: $grey-04;
        }
    }

    .btns {
        @include flex-row;

        .btn {
            height: 32px;
            padding: 0;
            margin: 0;
            font-size: 13px;
            border-radius: 6px;
            box-shadow: none;
            @include f-regular;
            @include center;
            border-radius: 8px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            width: 100%;
            &.btn-check {
                background: #03006e;
                color: #ffffff;
            }

            &.btn-final {
                background: #f1fffc;
                border: 1px solid #03006e;
                color: #03006e;
            }
        }
    }
}
</style>
